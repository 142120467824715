export function invariant(condition: any, message?: string): asserts condition {
  if (condition) return;

  if (import.meta.env.DEV && message) {
    throw new InvariantError(message);
  }

  throw new InvariantError("Invariant");
}

export function isInvariantError(error: any): error is InvariantError {
  return !!error && error instanceof InvariantError;
}

class InvariantError extends Error {
  name = "InvariantError";
}
