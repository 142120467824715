import { QueryClient, useQuery } from "@tanstack/react-query";
import { ReactElement } from "react";

import { Loading } from "~/components/loading";

import { fetchUser } from "~/routes/login/fetcher";

import { User } from "~/services/api-schemas";

import { ADMINISTRATOR_ROLE, TECHNICIAN_ROLE } from "~/utils/constants";
import { invariant } from "~/utils/invariant";
import { MINUTE_IN_MILLIS } from "~/utils/time";

export const CURRENT_USER_QUERY_KEY = ["currentUser"];

export type ExtendedUser = User & {
  isAdmin: boolean;
  isTechnician: boolean;
  deviceNotificationIds: string[] | undefined;
};

export function CurrentUserProvider({ children }: { children: ReactElement }) {
  // Refetch on window focus after 5 minutes of stale data
  const { isPending: isLoading, data: user } = useQuery({
    queryKey: CURRENT_USER_QUERY_KEY,
    queryFn: () => fetchUser(),
    refetchOnWindowFocus: true,
    staleTime: 5 * MINUTE_IN_MILLIS,
  });

  if (isLoading || !user) {
    return <Loading />;
  }

  return children;
}

export function useCurrentUser({ refetchOnMount }: { refetchOnMount?: boolean | "always" } = {}) {
  // Refetch on hook mount after 30 minutes of stale data
  const { data: user } = useQuery({
    queryKey: CURRENT_USER_QUERY_KEY,
    queryFn: () => fetchUser(),
    staleTime: 30 * MINUTE_IN_MILLIS,
    refetchOnMount,
  });

  invariant(user, "The hook `useCurrentUser` must be used within a `CurrentUserProvider`.");

  const deviceNotificationIds = user.notificationDevices?.map((device) => device.id);
  const extendedUser: ExtendedUser = {
    ...user,
    isAdmin: user?.role === ADMINISTRATOR_ROLE,
    isTechnician: user?.role === TECHNICIAN_ROLE,
    deviceNotificationIds,
  };
  return extendedUser;
}

export function refetchCurrentUser(queryClient: QueryClient) {
  queryClient.invalidateQueries({ queryKey: CURRENT_USER_QUERY_KEY });
}
